import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  detectBrowserLanguage,
  UserSettingsContext,
  useTranslationLoader,
  Spinner,
} from 'taulia-ui';
import { usePasswordEncryptionFetch } from '../hooks';
import LoginError from '../components/LoginError';

function UserSettingsContextProvider({ children, logger, session }) {
  const [locale, setLocale] = useState();
  const redirectUser = useCallback(
    redirectUrl => {
      logger.log(`Redirecting logged in user to [${redirectUrl}]`);
      window.location = redirectUrl;
    },
    [logger]
  );

  useEffect(() => {
    if (session?.locale) {
      setLocale(session.locale.replace('_', '-'));
    } else {
      const browserLanguage = detectBrowserLanguage();
      setLocale(browserLanguage.replace('_', '-'));
    }
  }, [session]);

  const {
    rsaEncrypt,
    refetchConfig,
    refetchingConfig,
    loading: passwordEncryptionConfigLoading,
    error: passwordEncryptionConfigError,
  } = usePasswordEncryptionFetch();

  const { complete: translationsLoaded, error: translationLoadingError } =
    useTranslationLoader(
      locale,
      session?.translationHash,
      '',
      '/api/i18n/translations',
      'app-login'
    );

  const contextValue = useMemo(
    () => ({
      activationLinkUrl: session.activationLinkUrl,
      logger,
      captchaSiteKey: session.captchaSiteKey,
      redirectUser,
      rsaEncrypt,
      refetchConfig,
    }),
    [session, logger, redirectUser, rsaEncrypt, refetchConfig]
  );

  if (passwordEncryptionConfigError || translationLoadingError) {
    return <LoginError />;
  }

  if (
    !translationsLoaded ||
    (passwordEncryptionConfigLoading && !refetchingConfig)
  ) {
    return <Spinner hasDelay showBackground={false} />;
  }

  return (
    <UserSettingsContext.Provider value={contextValue}>
      {children}
    </UserSettingsContext.Provider>
  );
}

export default UserSettingsContextProvider;

UserSettingsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  logger: PropTypes.shape().isRequired,
  session: PropTypes.shape({
    appVersion: PropTypes.string,
    activationLinkUrl: PropTypes.string,
    captchaSiteKey: PropTypes.string,
    translationHash: PropTypes.string,
    locale: PropTypes.string,
  }).isRequired,
};
